import React, { ReactNode, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import MenuBar from "./MenuBar";
import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils";

export default function Layout({
  childClassName,
  children,
  phone,
}: {
  childClassName?: string;
  children: ReactNode;
  phone?: string;
}) {
  const pathName = usePathname();
  const [isMenuBar, setIsMenuBar] = useState<boolean>(false);

  return (
    <div className="relative flex flex-col min-h-screen w-full">
      <MenuBar
        isOpen={isMenuBar}
        onClose={() => {
          setIsMenuBar(false);
        }}
      />

      <div
        className={cn(
          "flex-1",
          pathName?.toLowerCase().includes("astro-gold")
            ? "bg-white"
            : "bg-[#EEEBE6]"
        )}
      >
        <Header setIsMenuBar={setIsMenuBar} />
        <div className={`${childClassName} max-w-[1440px] mx-auto`}>
          {children}
        </div>
      </div>
      {!pathName?.toLowerCase().includes("astro-gold") && (
        <Footer phone={phone} />
      )}
    </div>
  );
}
