import mixpanel from "@/utils/mixpanel";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

export const Tabs: {
  name: string;
  redirectionLink: string;
  iconPath: string;
  iconPathGold: string;
  mixpanelEvent?: string;
}[] = [
  {
    name: "Home",
    redirectionLink: "/",
    iconPath: "/main-website/navbar-icons/home.svg",
    iconPathGold: "/main-website/navbar-icons/gold-icons/Home.svg",
  },
  {
    name: "Masterclasses",
    redirectionLink: "/masterclasses",
    iconPath: "/main-website/navbar-icons/masterclasses.svg",
    iconPathGold: "/main-website/navbar-icons/gold-icons/masterclass.svg",
    mixpanelEvent: "explore_masterclasses",
  },
  {
    name: "Bootcamps",
    redirectionLink: "/bootcamps",
    iconPath: "/main-website/navbar-icons/bootcamps.svg",
    iconPathGold: "/main-website/navbar-icons/gold-icons/bootcamp-gold.svg",
    mixpanelEvent: "explore_bootcamps",
  },
  {
    name: "Become an Instructor",
    redirectionLink: "/become-an-instructor",
    iconPath: "/main-website/navbar-icons/become-instructor.svg",
    iconPathGold:
      "/main-website/navbar-icons/gold-icons/become-an-instructor-gold.svg",
    mixpanelEvent: "become_instructor",
  },
  {
    name: "Blogs",
    redirectionLink: "/blogs",
    iconPath: "/main-website/navbar-icons/blog.svg",
    iconPathGold: "/main-website/navbar-icons/gold-icons/blog-gold.svg",
    mixpanelEvent: "explore_blog",
  },
  {
    name: "Astrolearn",
    redirectionLink: "/astro-gold",
    iconPath: "/main-website/navbar-icons/astrolearn-gold.svg",
    iconPathGold: "/main-website/navbar-icons/gold-icons/astrolearn-gold.svg",
    mixpanelEvent: "explore_gold",
  },
];

export default function MenuBar({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: any;
}) {
  const pathName = usePathname();
  // const { data: session, status } = useSession();
  return (
    <div className="bg-[url('/sidemenu.svg')] bg-no-repeat bg-cover max-w-[1440px] mx-auto">
      {isOpen && (
        // this gives overlay
        <div
          className="fixed inset-0 bg-black bg-opacity-70 z-30"
          onClick={onClose}
        ></div>
      )}

      <div
        className={`fixed top-0 bottom-0 w-[70%] bg-[#f5f1e9] bg-[url('/sidemenu.svg')] bg-no-repeat bg-cover ${
          pathName?.toLowerCase().includes("astro-gold")
            ? `border-r-[1px] border-r-[#FFD700] `
            : ""
        }  h-[99vh] overflow-hidden z-40 
        ${isOpen && "left-0"}  ${
          !isOpen && "-left-[100%]"
        } duration-300 ease-in-out transition-all delay-75
        `}
      >
        <div className="text-black mt-20 w-full px-4">
          {/* {session?.user && <NavBarProfileSection userData={session?.user} />} */}
          <div>
            {Tabs.map((item, index) => (
              <Link
                key={index}
                href={item.redirectionLink}
                className="text-black text-xl flex items-center w-full mb-8"
                onClick={() => {
                  mixpanel.track(item?.mixpanelEvent, {
                    page_location: "Homepage",
                    button_location: "navbar",
                  });
                }}
              >
                <p className="flex items-center gap-1.5 w-full">
                  <Image
                    src={
                      pathName?.toLowerCase().includes("astro-gold")
                        ? item.iconPathGold
                        : item.iconPath
                    }
                    width={30}
                    height={30}
                    alt="menu-icon"
                  />
                  {item.name}
                  {item.redirectionLink === "/astro-gold" && (
                    <span className="uppercase text-yellow-400 font-semibold">
                      gold
                    </span>
                  )}
                </p>
                <Image
                  src="/main-website/navbar-icons/arrow-right.svg"
                  width={25}
                  height={25}
                  alt="right arrow"
                />
              </Link>
            ))}
          </div>

          <div className="absolute bottom-16 left-0 w-full px-4">
            <div className="h-0.5 rounded-full bg-[#D1D1D14D] w-full" />
            <Link
              href="/contact-us"
              className="text-black mt-4 text-xl flex justify-between"
              onClick={() =>
                mixpanel.track("contact_us", {
                  page_location: "Homepage",
                  button_location: "navbar",
                })
              }
            >
              <p>
                <Image
                  src={"/main-website/navbar-icons/contact-us.svg"}
                  width={30}
                  height={30}
                  alt="menu-icon"
                />
                Contact Us
              </p>
              <Image
                src="/main-website/navbar-icons/arrow-right.svg"
                width={25}
                height={25}
                alt="right arrow"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
