import Image from "next/image";
import Link from "next/link";
import mixpanel from "@/utils/mixpanel";
import webengage from "@/webengage";

const socialLinks = [
  {
    href: "https://www.facebook.com/p/AstroLearn-100084571099049/",
    iconSrc: "/footer/facebook.svg",
  },
  {
    href: "https://www.instagram.com/astrolearnofficial/",
    iconSrc: "/footer/instagram.svg",
  },
  {
    href: "https://www.youtube.com/channel/UCi_rtQiqQQ2VIUtTgqPfedw",
    iconSrc: "/footer/youtube.svg",
  },
];

export default function Footer({
  phone = "080-468-10701",
}: {
  phone?: string;
}) {
  return (
    <footer className="bg-[#020717] text-white py-8 md:py-10 w-full font-lato px-4">
      <div className="flex flex-col w-full gap-6 font-lato max-md:items-center max-md:justify-center max-w-[1440px] mx-auto md:px-8">
        <div className="flex max-md:items-center justify-between w-full">
          <div className="max-md:hidden flex flex-col justify-between py-5">
            <Link
              href="/"
              className="flex gap-2 items-center w-full hover:cursor-pointer font-montserrat text-xl xl:text-2xl text-white hover:no-underline"
            >
              <Image
                src="/img/logo.png"
                width={48}
                height={48}
                alt="logo"
                className="w-9 md:w-12 rounded-md"
              />
              AstroLearn
            </Link>
            <Link
              href="/become-an-instructor"
              className="xl:text-2xl text-lg text-white hover:underline hover:cursor-pointer"
              onClick={() =>
                mixpanel.track("become_instructor", {
                  page_location: "Homepage",
                  button_location: "footer",
                })
              }
            >
              Become An Instructor
            </Link>
          </div>
          <div className="flex flex-col max-md:items-center max-md:gap-4 md:gap-6 md:text-start max-md:w-full">
            <div className="flex gap-2 justify-center items-center w-full md:hidden">
              <Image
                src="/img/logo.png"
                width={48}
                height={48}
                alt="logo"
                className="w-9 md:w-12 rounded-md"
              />
              <p className="font-montserrat text-2xl md:text-3xl">AstroLearn</p>
            </div>

            <Link
              href={`tel:7973938627`}
              target="_blank"
              className="flex items-center gap-1 md:gap-6 text-2xl text-white"
              onClick={() => {
                const _webengage = webengage();
                if (_webengage) {
                  _webengage.track("Contact Us", {
                    source: "Astrolearn",
                    type: "Call",
                  });
                }
                mixpanel.track("support_call", {
                  location: "footer",
                  page_location: "Homepage",
                });
              }}
            >
              <Image
                src="/footer/phone.svg"
                height={16}
                width={16}
                alt="phone icon"
                className="md:w-6"
              />
              <p className="text-sm md:text-base xl:text-xl hover:underline hover:cursor-pointer">
                Call us directly on 797-393-8627
              </p>
            </Link>
            <Link
              href="https://wa.me/+919056187997"
              target="_blank"
              className="flex items-center gap-1 md:gap-6 text-2xl text-white"
              onClick={() => {
                const _webengage = webengage();
                if (_webengage) {
                  _webengage.track("Contact Us", {
                    source: "Astrolearn",
                    type: "Chat",
                  });
                }
                mixpanel.track("support_whatsapp", {
                  location: "footer",
                  page_location: "Homepage",
                });
              }}
            >
              <Image
                src="/footer/whatsapp.svg"
                height={16}
                width={16}
                alt="whatsapp icon"
                className="md:w-6"
              />
              <p className="text-sm md:text-base xl:text-xl hover:underline hover:cursor-pointer">
                Whatsapp us on +919056187997
              </p>
            </Link>
            <div className="flex justify-center gap-6 max-md:hidden">
              <Image
                src="/footer/location.svg"
                height={24}
                width={24}
                alt="location icon"
                className="object-contain"
              />
              <p>
                D-178, La Daftar, Phase 8B, Sector-74, SAS Nagar <br />
                Mohali, Punjab, India 140308
              </p>
            </div>
            <div className="flex items-center justify-center gap-3 w-full text-sm md:text-lg md:hidden">
              <Link
                href="/privacy-policy"
                className="text-white"
                onClick={() => {
                  mixpanel.track("privacy_policy", {
                    page_location: "Homepage",
                    location: "Footer",
                  });
                }}
              >
                Privacy Policy
              </Link>
              <span>|</span>
              <Link
                href="/terms-and-conditions"
                className="text-white"
                onClick={() => {
                  mixpanel.track("terms_and_conditions", {
                    page_location: "Homepage",
                    location: "Footer",
                  });
                }}
              >
                Terms and Conditions
              </Link>
            </div>
            <p className="text-center text-sm md:hidden">
              D-178, La Daftar, Phase 8B, Sector-74, SAS Nagar Mohali,<br></br>
              Punjab, India 140308
            </p>
            <div className="flex gap-2 items-center justify-center md:hidden">
              {socialLinks.map((item, index) => (
                <Link href={item.href} key={index}>
                  <Image width={30} height={30} src={item.iconSrc} alt="icon" />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full h-0.5 bg-gray-500 max-md:hidden" />
        <div className="flex items-center justify-between w-full max-md:hidden">
          <div className=" flex items-center justify-center gap-3 text-sm md:text-lg">
            <Link
              href="/privacy-policy"
              className="text-white"
              onClick={() => {
                mixpanel.track("privacy_policy", {
                  page_location: "Homepage",
                  location: "Footer",
                });
              }}
            >
              Privacy Policy
            </Link>
            <span>|</span>
            <Link
              href="/terms-and-conditions"
              className="text-white"
              onClick={() => {
                mixpanel.track("terms_and_conditions", {
                  page_location: "Homepage",
                  location: "Footer",
                });
              }}
            >
              Terms and Conditions
            </Link>
          </div>
          <div className="flex gap-2 items-center justify-center">
            {socialLinks.map((item, index) => (
              <Link href={item.href} key={index}>
                <Image width={30} height={30} src={item.iconSrc} alt="icon" />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
